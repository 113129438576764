import Model from './model';
import { Address } from '@/application/models/Address.js';

export class LocationDetails extends Model {
  name = '';
  phoneNumber = '';
  mobilePhoneNumber = '';
  email = '';
  shippingAddress = new Address();

  mapForRequest() {
    return this;
  }

  mapResponse(data) {
    data.shippingAddress = this.shippingAddress.mapResponse(data.shippingAddress ?? {});
    return super.mapResponse(data);
  }
}
