<template>
  <v-row class="ma-0">
    <v-col cols="12" md="8">
      <v-sheet class="pa-3">
        <v-row class="ma-0">
          <v-icon class="mr-3">$filter</v-icon>
          <v-chip-group
              v-model="params.storeFileCategoryId"
              active-class="primary"
              column
          >
            <v-chip
                v-for="category in categories"
                :key="category.value"
                :value="category.value"
            >
              {{ category.text }}
            </v-chip>
          </v-chip-group>
        </v-row>
        <FileBatches :index-request="fileBatchesIndexRequest" :params="params" ref="fileBatches"/>
      </v-sheet>
    </v-col>
    <v-col cols="12" md="4">
      <StoreDimensions :store-id="storeId"/>
      <Address :store-id="storeId" class="mt-6"/>
    </v-col>
  </v-row>
</template>

<script>
import storeFileCategories from '@/application/enums/storeFileCategories.js';
import { index } from '@/modules/locationDetails/api/locationDetails.js';
import { mapGetters } from 'vuex';
import FileBatches from '@/modules/locationDetails/components/FileBatches.vue';
import StoreDimensions from '@/modules/locationDetails/components/StoreDimensions.vue';
import Address from '@/modules/locationDetails/components/Address.vue';

export default {
  name: 'Documents',
  components: {
    Address,
    StoreDimensions,
    FileBatches,
  },
  props: {
    storeId: {
      type: Number,
    }
  },
  data: () => ({
    form: {
      files: [],
      storeFileCategoryId: 1,
    },
    isLoading: false,
    params: {
      storeFileCategoryId: undefined
    }
  }),
  computed: {
    categories() {
      return Object.keys(storeFileCategories).map(type => {
        return {
          value: storeFileCategories[type],
          text: this.$t(`locationDetails.storeFileCategories.${type}`),
        };
      });
    },
  },
  methods: {
    fileBatchesIndexRequest(...args) {
      return index(this.storeId, ...args);
    },
  },
};
</script>

<style scoped>

</style>
