import Model from './model';

export class StoreDimensions extends Model {
  area = 0;
  length = 0;
  width = 0;
  height = 0;

  mapForRequest() {
    return this;
  }
}
