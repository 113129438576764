import { getPaginated } from '@/application/api/implementations/app/wrapper.js';
import { get } from '@/application/api/implementations/app';

export const index = (storeId, ...args) => getPaginated(`/store/${storeId}/file-batch`, ...args);

export const batch = (fileBatchId, ...args) => getPaginated(`file-batch/${fileBatchId}/file`, ...args)

export const dimensions = (storeId, ...args) => get(`/store/${storeId}/store-dimension`, ...args);

export const address = (storeId, ...args) => get(`store/${storeId}/address`, ...args)
