<template>
  <div>
    <div v-for="batch in paginator.data" :key="batch.id" class="batch-wrapper mb-12">
      <v-row class="ma-0 mb-3">
        <span class="body-2">{{ $t('locationDetails.documents.batchTitle', batch) }}</span>
        <span class="body-2 text--disabled ml-1 font-italic">{{
            $t('locationDetails.documents.batchCaption', batch)
          }}</span>
      </v-row>
      <FileBatch :file-batch-id="batch.id"/>
    </div>
    <VDataFooter :itemsPerPageOptions="[3,10,25]"
                 :options="this.pagination"
                 :pagination="vuetifyPagination"
                 @update:options="updatePagination"
    />
  </div>
</template>

<script>
import Paginator from '@/application/mixins/Paginator.vue';
import FileBatch from '@/modules/locationDetails/components/FileBatch.vue';

export default {
  name: 'FileBatches',
  components: { FileBatch },
  mixins: [Paginator],
  computed: {
    totalItems() {
      return this.paginator?.meta?.total;
    },
    vuetifyPagination() {
      return {
        page: this.pagination.page,
        itemsPerPage: this.pagination.perPage,
        pageStart: this.paginator?.meta?.from - 1,
        pageStop: this.paginator?.meta?.to,
        pageCount: this.paginator?.meta?.lastPage,
        itemsLength: this.totalItems,
      };
    },
  },
  created() {
    this.pagination.perPage = 3;
    this.reloadPaginator();
  },
};
</script>

<style scoped lang="scss">
@include theme(v-application) using($material) {
  .batch-wrapper {
    border-left-width: 2px;
    border-left-color: map-deep-get($material, 'text', 'disabled');
    border-left-style: solid;
    padding-left: 22px;
  }
}
</style>
