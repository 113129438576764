<template>
  <page-layout>
    <template #header>
      <div class="mb-8">
        <k-title>{{ $t('locationDetails.pageTitle') }}</k-title>
      </div>
    </template>

    <template>
      <v-row class="mx-0">
        <v-col cols="4"><StoreAutocomplete field="store" v-model="store" solo/></v-col>
      </v-row>
      <Documents :store-id="store.id" v-if="store"/>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import Documents from '@/modules/locationDetails/views/Documents.vue';
import StoreAutocomplete from '@/modules/store/components/StoreAutocomplete.vue';

export default {
  name: 'LocationDetails',
  components: {
    StoreAutocomplete,
    Documents,
    KTitle,
    PageLayout,
  },
  data: () => ({
    store: null,
  }),
};
</script>
