<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete(clientId)"/>
</template>

<script>
import { Store } from '@/application/models/Store.js';
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/modules/store/api/store.js';
import { mapGetters } from 'vuex';

export default {
  name: 'StoreAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete: (clientId) => async (...args) => {
      const response = await autocomplete(clientId, ...args);
      response.data.data = response.data.data.map((store) => new Store().mapResponse(store))
      return response
    }
  },
  computed: {
    ...mapGetters('settings', {clientId: 'getCurrentClientId' }),
  },
};
</script>
