<template>
  <v-row class="mt-0 px-2" v-if="batch.length === 0">
    <v-col cols="2" v-for="i in 6" :key="i">
      <v-responsive :aspect-ratio="1">
        <VSkeletonLoader type="image" width="100%" height="100%"/>
      </v-responsive>
    </v-col>
  </v-row>
  <v-row class="mt-0 px-2" v-else>
    <v-col>
      <v-row>
        <v-window
            v-model="pagination.loadedPage"
            style="width: 100%"
        >
          <v-window-item
              v-for="n in pagination.totalPages"
              :key="`card-${n}`"
          >
            <v-row class="pa-6">
              <v-col cols="2"
                     v-for="file in batch[n-1]"
                     :key="file.id"
                     class="cursor-pointer position-relative"
                     @click="openFile(file)">
                <v-btn icon tile color="white" class="black mr-n1" small absolute right style="z-index: 1">
                  <v-icon small>$download</v-icon>
                </v-btn>
                <v-responsive :aspect-ratio="1" class="justify-center align-center text-center">
                  <v-img :src="`${file.url}&type=thumbnail`"
                         :aspect-ratio="1"
                         min-height="100%"
                         min-width="100%"
                         :lazy-src="`${file.url}&type=lazy`"
                         v-if="typeIsImage(file.type)"/>
                  <div v-else>
                    <v-icon class="mb-3" large>{{ getFileTypeIcon(file.type) }}</v-icon>
                    <div class="text-body-2 text-truncate" :title="file.name">{{ file.name }}</div>
                  </div>
                </v-responsive>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-row>
      <v-row class="justify-center" v-if="pagination.totalPages > 1">
        <v-item-group
            v-model="pagination.requestedPage"
            class="shrink mr-6"
            mandatory
        >
          <v-item
              v-for="n in pagination.totalPages"
              :key="n"
              v-slot="{ active, toggle }"
          >
            <v-btn
                :input-value="active"
                icon
                @click="toggle"
            >
              <v-icon small>$circle</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { batch } from '@/modules/locationDetails/api/locationDetails.js';
import { get } from '@/application/api/implementations/app';
import downloadFile from '@/application/util/downloadFile.js';
import { getFileTypeIcon, typeIsImage } from '@/application/util/displayFile.js';

export default {
  name: 'FileBatch',
  props: {
    fileBatchId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    batch: [],
    pagination: {
      requestedPage: 0,
      loadedPage: 0,
      totalPages: 1,
    },
  }),
  watch: {
    fileBatchId: {
      immediate: true,
      handler() {
        this.fetchBatch();
      },
    },
    'pagination.requestedPage': {
      handler() {
        this.fetchBatch();
      },
    },
  },
  methods: {
    getFileTypeIcon,
    typeIsImage,
    async fetchBatch() {
      if (!this.batch[this.pagination.requestedPage]) {
        const response = await batch(this.fileBatchId, (this.pagination.requestedPage + 1), 6);
        this.$set(this.batch, this.pagination.requestedPage, response.data.data);
        this.pagination.totalPages = response.data.meta.lastPage;
      }
      this.pagination.loadedPage = this.pagination.requestedPage;
    },
    openFile(file) {
      downloadFile(get(file.url, { responseType: 'blob' }), file.name);
    },
  },
};
</script>

<style scoped lang="scss">
// @TODO https://github.com/vuetifyjs/vuetify/issues/11771
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}
// @TODO this is a vuetify bug, when you remove this on transitioning the height is fluctuating
::v-deep .v-window__container {
  height: auto!important;
}

.position-relative {
  position: relative;
}
</style>
