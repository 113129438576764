import { Address } from '@/application/models/Address.js';
import Model from './model';

export class Store extends Model {
  id = 0;
  name = '';
  email = '';
  floorplannerProjectId = '';
  dealerCode = 0;
  dealerLevel = '';
  branchExtension = '';
  phone = '';
  mobilePhone = '';
  shipmentAddress = new Address();
  billingAddress = new Address();
  owners = [];
  approvers = [];

  mapForRequest() {
    return {
      name: this.name,
      email: this.email,
      floorplannerProjectId: this.floorplannerProjectId,
      dealerCode: this.dealerCode,
      dealerLevel: this.dealerLevel,
      branchExtension: this.branchExtension,
      phone: this.phone,
      mobilePhone: this.mobilePhone,
      shipmentAddress: this.shipmentAddress.mapForRequest(),
      billingAddress: this.billingAddress.mapForRequest(),
      owners: this.owners.map((item) => item.id),
      approvers: this.approvers.map((item) => ({
        userId: item.id,
        order: item.order,
      })),
    };
  }
}
