import Model from './model';

export class Address extends Model {
  countryId = 0;
  addressLine1 = '';
  addressLine2 = '';
  zipCode = '';
  city = '';

  mapForRequest() {
    return this;
  }
}
