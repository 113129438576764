<template>
  <v-sheet class="pa-3">
    <k-display-group language-prefix="locationDetails.address.fields">
      <h3 class="text-h3">{{ $t('locationDetails.address.title') }}</h3>
      <v-row v-if="!loading">
        <v-col cols="6">
          <k-text-display field="contact">{{ locationDetails.name }}</k-text-display>
          <k-text-display field="phoneNumber">{{ locationDetails.phoneNumber }}</k-text-display>
          <k-text-display field="mobilePhoneNumber">{{ locationDetails.mobilePhoneNumber }}</k-text-display>
          <k-text-display field="email">{{ locationDetails.email }}</k-text-display>
        </v-col>
        <v-col cols="6">
          <k-text-display field="shippingAddress">
            {{ locationDetails.shippingAddress.addressLine1 }} <br/>
            {{ locationDetails.shippingAddress.zipCode }} <br/>
            {{ locationDetails.shippingAddress.city }} <br/>
            {{ $t(`countries.${locationDetails.shippingAddress.countryId}`) }}
          </k-text-display>
        </v-col>
      </v-row>
    </k-display-group>
  </v-sheet>
</template>

<script>
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import { address } from '@/modules/locationDetails/api/locationDetails.js';
import { LocationDetails } from '@/application/models/LocationDetails.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';

export default {
  name: 'Address',
  components: {
    KDisplayGroup,
    KTextDisplay,
  },
  props: {
    storeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locationDetails: new LocationDetails(),
      loading: false,
    };
  },
  created() {
    this.getAddress();
  },
  methods: {
    async getAddress() {
      this.loading = true;
      const { data: { data } } = await address(this.storeId);
      this.locationDetails.mapResponse(data);
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
