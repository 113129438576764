<template>
  <v-sheet class="pa-3">
    <k-display-group language-prefix="locationDetails.commercialNotes.fields">
      <h3 class="text-h3">{{ $t('locationDetails.commercialNotes.storeDimensions') }}</h3>
      <v-row>
        <v-col cols="6">
          <k-text-display field="area" suffix="m²">{{ dimensions.area }}</k-text-display>
          <k-text-display field="length" suffix="m">{{ dimensions.length }}</k-text-display>
        </v-col>
        <v-col cols="6">
          <k-text-display field="height" suffix="m">{{ dimensions.height }}</k-text-display>
          <k-text-display field="width" suffix="m">{{ dimensions.width }}</k-text-display>
        </v-col>
      </v-row>
    </k-display-group>
  </v-sheet>
</template>

<script>
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import { dimensions } from '@/modules/locationDetails/api/locationDetails.js';
import { StoreDimensions } from '@/application/models/StoreDimensions.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';

export default {
  name: 'StoreDimensions',
  components: {
    KDisplayGroup,
    KTextDisplay,
  },
  props: {
    storeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dimensions: new StoreDimensions(),
    };
  },
  created() {
    this.getDimensions();
  },
  methods: {
    async getDimensions() {
      const { data: { data } } = await dimensions(this.storeId);
      this.dimensions.mapResponse(data);
    },
  },
};
</script>

<style scoped>

</style>
